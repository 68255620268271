@import './base.scss';

.fargions {
  margin-top: 150px;
  background-color: white;
}

.fargion-header {
  margin-left: 20px;
}

h3 {
  white-space: nowrap;
}
.text-container {
  width: 500px;
  overflow-wrap: break-word;
}
.text-container-fargionsbio {
  // width: 100%;
  // width: 300px;
  min-width: 250px;
}
// @media (max-width:500px) {
//   .text-container-fargionsbio {
//    width: 360px;
//    margin-bottom: 100px;
//  }
//  @media (max-width:320px) {
//   .text-container-fargionsbio {
//     width: 250px;
//   }
//  }
// }
.text-container p {
  font-size: 15px;
  line-height: 2rem;
  word-wrap: break-word;
}

.item img {
  max-width: 600px;
  object-fit: cover;
  border-radius: 5px;
  margin-left: 20px;
  // margin-top: 1000px;
  @media (max-width:600px) {
    width: 450px;
  }
}


section.horizontal {
  overflow-x: hidden;
}

section.horizontal .pin-wrap,
section.horizontal .animation-wrap {
    display: flex;
    position: relative;
    z-index: 1;
    height: 100vh;
}


section.horizontal .item {
    position: relative;
    padding: 150px 80px;
    flex: 0 0 500px;
    // height: calc(100vh - 300px);
    display: flex;
    align-items: center;
    line-height: 1.7;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-right: 1px solid rgba(0, 0, 0, 0.06);
    background-color: transparent;
    @media (max-width:425px) {
      padding: 50px;
    }
}

section.horizontal .item:before {
    position: absolute;
    font-size: 100px;
    opacity: .13;
    font-weight: bold;
    z-index: -1;
    -webkit-transform: translate(-30px, -50px);
    transform: translate(-30px, -50px);
    line-height: 1;
}

section.horizontal .animation-wrap .item:nth-child(2n+2) {
    align-items: flex-start;
}

section.horizontal .animation-wrap .item:nth-child(4n+4) {
    align-items: flex-end;
}


@media (max-width:560px) {
  #fargionbio {
    overflow-wrap: break-word;
  }
}
