@import './base';

.mastering {
  position: relative;
  font-size: 50px;
  height: 600px;
  background: black;
  overflow: hidden;
  text-align: center;
  padding-top: 1rem;
  z-index: 1;
  &__header {
    font-weight: 600;
    color: white;
  }
  &__text {
    position: absolute;
    white-space: nowrap;
    color: white;
    margin-top: 100px;
    font-size: 30px;
    &--left {
      left: 0;
      top: 5rem;
      animation: moveleft 40s linear infinite;
      @keyframes moveleft {
        0%{
          transform: translate3d(0, 0, 0);
        }
        100% {
          transform: translate3d(-50%, 0, 0);
        }
      }
    }
    &--right {
      right: 0;
      top: 20rem;
      animation: moveright 40s linear infinite;
      @keyframes moveright {
        0%{
          transform: translate3d(0, 0, 0);
        }
        100% {
          transform: translate3d(50%, 0, 0);
        }
      }
    }
  }

  .mastering__details {
      color: white;
      font-size: 25px;
      padding-top: 5px;
  }

  @media (max-width: 700px) {
    &{
      height: 400px;
    }
    &__text {
      font-size: 25px;
      &--left{
        top: 5rem;
      }
      &--right{
        top: 11rem;
      }
    }
  }
}
