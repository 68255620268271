.projects-container {
  padding: 30px;
}

.projects{
  &__header{
    // font-size: 60px;
    font-size: italic;
    padding: 50px;
  }
}

.project{
  &__header{
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 1rem;
    margin-top: 30px;
    span {
    border-top: 1px solid black;
    text-align: center;
    font-size: 30px;
    }
  }
  &__infos{
    margin-top: 1rem;
    &--name{
      position: relative;
      font-size: 6vw;
      display: flex;
      align-items: center;
    }
  }
  &__img {
    position: relative;
    max-width: 100vw;
    height: 700px;
    img {
      width: 60vw;
      max-height: 600px;
      object-fit: cover;
      position: absolute;
      right: 7vw;
      top: 3rem;
    }
  }
  &__links {
    padding-top: 3rem;
  }
  &__description {
    position: absolute;
    margin-top: 3rem;
    width: 25vw;
    span {
      font-size: 15px;
    }
  }
}

.coolCircleEyeButton {
  position: relative;
  display: inline-block;
  .textcircle {
    display: block;
    width: 205px;
    text{
      font-size: 32px;
      text-transform: uppercase;
      fill: black;
    }
  }
  @keyframes rotate {
    to{
      transform: rotate(-360deg);
    }
  }
  &:hover {
    text.circle text{
    font-weight: 600;
    }
    animation: rotate 7s linear infinite;
  }
}

.eye {
  position: absolute;
  width: 60px;
  height: 60px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &__outer, &__inner, &__lashes-up, &__lashes_down {
    stroke: black;
    fill: none;
    stroke-width: 1.5px;
  }
}

@media (max-width: 740px) {
  .project__description {
    text-align: center; /* Centers the text */
  }
  .project__description p {
    margin-top: 45px; /* Increase this value as needed */
    width: 70vw;
    margin-left: auto; /* Centers the paragraph horizontally */
    margin-right: auto; /* Centers the paragraph horizontally */
  }
}

//Hover effects removed for mobile
@media (max-width: 768px) {
  .coolCircleEyeButton {
    .textcircle {
      animation: rotate 7s linear infinite; /* Continuous rotation */
    }

    /* Remove hover effects on mobile */
    &:hover {
      .textcircle {
        animation: none; /* Stop animation on hover for mobile */

      }
    }
  }
}
