@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  box-sizing: border-box;
  &:after,
  &:before {
    box-sizing: border-box;
  }
}
html, body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}
body {
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  // -webkit-font-smoothing: antialised;
}
button,
input,
textarea,
select {
  font: inherit;
}


p,h1,h2,h3,h4,h5,h6 {
  // overflow-wrap: break-word;
}

h1 {
  font-size: 60px;
  @media (max-width: 320px) {
    font-size: 40px;
  }
}

img,video,canvas,svg {
  // max-width: 100%;
}
a {
  text-decoration: none;
  color: inherit;
  z-index: 2;
  pointer-events: auto;
}
ul {
  list-style: none;
}


/*Variables */


$font-family: "Poppins", sans-serif;
$primary-color: black;
$secondary-color: #e1ecf7;

.section {
  padding: 1rem;
  overflow: hidden;
  min-height: 100vh;
  padding-top: 5rem;
  @media (min-width: 650px) {
    padding: 5rem;
  }
}
