@import './base';

.contact {
  padding: 50px;
}

.contact-container {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 90vh;
  border-radius: 10px;
  overflow: hidden;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  font-weight: bold;

  &::before {
    content: '';
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #feffff;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(#c4d3c8, #f9f9f9), linear-gradient(#e2c98a, #fbb300), linear-gradient(#ffffff, #f5dddc), linear-gradient(#377af5, #377af5);
    animation: rotate 15s linear infinite;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    background: black;
    border-radius: 3px;
  }
}


@keyframes rotate {
	100% {
		transform: rotate(1turn);
	}
}



.contact-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 5px;
  word-wrap: break-word;
  overflow-wrap: break-word;

}

.contact-text span {
  font-size: 20px;
  color: white;
}

.contact-text h2 {
  font-size: 50px;
  color: white;
}

.contact-text button {
  background: transparent;
  border-radius: 50px;
  border: 1px solid white;
  padding: 20px;
  z-index: 1;
  color: white;
}


/* Media query for devices with width up to 1024px */
@media (max-width: 1024px) {
  .contact-container {
    width: 90vw;
  }
}

/* Media query for devices with width up to 900px */
@media (max-width: 900px) {
  .contact-text span {
    font-size: 15px;
  }

  .contact-text h2 {
    font-size: 25px;
  }
}

/* Media query for devices with width up to 600px */
@media (max-width: 600px) {
  .contact {
    padding: 20px;
  }

  .contact-container {
    height: auto;
    border-radius: 20px;
  }

  .contact-text {
    padding: 10px;
  }

  .contact-text span {
    font-size: 14px;
  }

  .contact-text h2 {
    font-size: 20px;
  }

  .contact-text button {
    padding: 10px;
  }
}
