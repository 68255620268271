@import "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
@media (width <= 320px) {
  h1 {
    font-size: 40px;
  }
}

@media (width >= 650px) {
  .section {
    padding: 5rem;
  }
}

.loading {
  z-index: 99;
  background: #191919;
  place-items: center;
  width: 100%;
  height: 100vh;
  display: grid;
  position: fixed;
  inset: 0;
}

.loading__box {
  border: 3px solid #e1ecf7;
  border-color: #e1ecf7 #000;
  width: 90vw;
  height: 200px;
  position: relative;
}

@media (width >= 600px) {
  .loading__box {
    width: 500px;
  }
}

.loading__text {
  color: #fff;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  position: relative;
}

@media (width <= 355px) {
  .loading__text {
    font-size: 15px;
  }
}

.loading__bar {
  background: #fff;
  border-radius: 2px;
  width: 90%;
  height: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}

.loading__bar--inner {
  background: #e1ecf7;
  border-radius: 2px;
  width: 0;
  height: 100%;
}

.loading__counter {
  color: #fff;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  position: absolute;
  top: 70%;
  left: 0;
}

.loading__counter--number {
  color: #e1ecf7;
}

.loading__message {
  opacity: 0;
  color: #fff;
  font-size: 30vw;
  font-weight: 700;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (width >= 425px) {
  .loading__message {
    font-size: 200px;
    top: 90%;
  }
}

@media (width <= 320px) {
  h1 {
    font-size: 40px;
  }
}

@media (width >= 650px) {
  .section {
    padding: 5rem;
  }
}

.landing {
  background: none;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.landing .header {
  z-index: 2;
  position: absolute;
  top: 1rem;
  left: 60%;
  transform: translateX(-45%);
}

.landing .header__wrap {
  width: 60vw;
  padding-top: 30px;
}

.landing .header__left {
  align-items: center;
  gap: 2.9rem;
  display: flex;
}

.landing .header__left li {
  font-weight: 700;
}

@media (width <= 1200px) {
  .landing .header__wrap {
    width: 100vw;
  }
}

@media (width <= 500px) {
  .landing .header__wrap {
    display: none;
  }
}

.hamburger-nav {
  display: none;
}

.hamburger-menu {
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 25px;
  padding: 10px;
  font-weight: 700;
  position: absolute;
  top: 10px;
  left: 50px;
}

.btn-toggle {
  cursor: pointer;
  background-color: #0000;
  border: 2px solid #000;
  margin-left: 10px;
  font-size: 30px;
}

.menu-links {
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  transition: max-height .5s ease-in-out, opacity .5s ease-in-out, visibility .5s;
  position: absolute;
  right: 43px;
  overflow: hidden;
}

.menu-links a {
  text-align: center;
  padding: 8px;
  font-size: 1.4rem;
  text-decoration: none;
  display: block;
}

.menu-links li {
  list-style: none;
}

.menu-links.active {
  visibility: visible;
  opacity: 1;
  max-height: 300px;
}

@media (width <= 500px) {
  .header__left {
    display: none;
  }

  .hamburger-nav {
    display: block;
  }
}

.link-hover {
  place-items: center;
  display: grid;
  position: relative;
  overflow: hidden;
}

.link-hover:before {
  content: "";
  transform-origin: 100%;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scale3d(.5, 1, 1);
}

.link-hover:after {
  content: attr(data-text);
  height: 100%;
  bottom: 0;
  transform: translate3d(150%, 0, 0);
}

.link-hover span {
  transition: transform .6s ease-in-out;
}

.link-hover:hover:before {
  transform-origin: 0;
  background: currentColor;
  transform: scale3d(1, 1, 1);
}

.link-hover:hover:after {
  font-size: 1.5rem;
  transition: transform .3s ease-in-out;
  transform: translate3d(0, 0, 0);
}

.link-hover:hover span {
  transform: translate3d(-150%, 0, 0);
}

.contact-button {
  cursor: pointer;
  color: #000;
  background: none;
  border: none;
  width: 175px;
  height: 120px;
  position: relative;
}

.contact-button:before, .contact-button:after {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-button:before {
  content: "";
  clip-path: polygon(16% 21%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0 76%);
  z-index: 1;
  transition: transform .5s;
  transform: rotate(20deg);
}

.contact-button:hover:before {
  transform: rotate(-20deg);
}

.contact-button span {
  font-weight: 500;
}

.contact-button:after {
  content: "";
  clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0 76%);
  border: 1px solid #e1ecf7;
  width: 95%;
  height: 85%;
  transition: transform .5s ease-in-out;
  transform: rotate(-20deg);
}

.contact-button:hover:after {
  transform: translate3d(0, -5px, 0);
}

@media (width <= 800px) {
  .contact-button {
    transform: scale3d(.7, .7, .7);
  }
}

.colouredIcon {
  font-size: 22px;
}

.colouredIcon:hover {
  animation: .5s infinite colouredIconAnimation;
}

@keyframes colouredIconAnimation {
  0% {
    text-shadow: -5px -4px #e1ecf7, -5px -6px #000;
  }

  25% {
    text-shadow: -10px -20px #000, -5px -6px #e1ecf7;
  }

  50% {
    text-shadow: -10px -20px #e1ecf7, -5px -6px #000;
  }

  75% {
    text-shadow: -10px -20px #000, -5px -6px #e1ecf7;
  }
}

.socials {
  z-index: 2;
  align-items: center;
  gap: 1rem;
  display: flex;
  position: absolute;
  top: 300px;
  bottom: -100%;
  left: 50%;
  transform: translateX(-50%);
}

.socials i {
  font-size: 30px;
}

.scrolldown {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  display: flex;
  position: absolute;
  bottom: -100%;
  left: 50%;
  transform: translateX(-50%);
}

.scrolldown__wheel {
  border: 2px solid #fff;
  border-radius: 10px;
  width: 24px;
  height: 42px;
  position: relative;
}

.scrolldown--inner {
  background-color: #fff;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  margin-top: 5px;
  animation: .5s linear infinite movingwheel;
  position: absolute;
  left: 8px;
}

@keyframes movingwheel {
  0% {
    top: 1px;
  }

  25% {
    top: 2px;
  }

  50% {
    top: 3px;
  }

  75% {
    top: 2px;
  }

  100% {
    top: 1px;
  }
}

.scrolldown__arrows {
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.scrolldown__arrow {
  border-bottom: 2px solid #a6a6a6;
  border-right: 2px solid #fff;
  width: 15px;
  height: 15px;
  animation: 1s infinite scrollAnime;
  transform: rotate(45deg);
}

.scrolldown__arrow:nth-child(2) {
  animation-delay: .2s;
}

.scrolldown__arrow:nth-child(3) {
  animation-delay: .4s;
}

@keyframes scrollAnime {
  0% {
    opacity: 1;
    transform: translateY(0)rotate(45deg);
  }

  50% {
    opacity: .5;
    transform: translateY(10px)rotate(45deg);
  }

  100% {
    opacity: 0;
    transform: translateY(20px)rotate(45deg);
  }
}

.projects-container {
  padding: 30px;
}

.projects__header {
  font-size: italic;
  padding: 50px;
}

.project__header {
  grid-template-columns: 1fr 3fr;
  gap: 1rem;
  margin-top: 30px;
  display: grid;
}

.project__header span {
  text-align: center;
  border-top: 1px solid #000;
  font-size: 30px;
}

.project__infos {
  margin-top: 1rem;
}

.project__infos--name {
  align-items: center;
  font-size: 6vw;
  display: flex;
  position: relative;
}

.project__img {
  max-width: 100vw;
  height: 700px;
  position: relative;
}

.project__img img {
  object-fit: cover;
  width: 60vw;
  max-height: 600px;
  position: absolute;
  top: 3rem;
  right: 7vw;
}

.project__links {
  padding-top: 3rem;
}

.project__description {
  width: 25vw;
  margin-top: 3rem;
  position: absolute;
}

.project__description span {
  font-size: 15px;
}

.coolCircleEyeButton {
  display: inline-block;
  position: relative;
}

.coolCircleEyeButton .textcircle {
  width: 205px;
  display: block;
}

.coolCircleEyeButton .textcircle text {
  text-transform: uppercase;
  fill: #000;
  font-size: 32px;
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

.coolCircleEyeButton:hover {
  animation: 7s linear infinite rotate;
}

.coolCircleEyeButton:hover text.circle text {
  font-weight: 600;
}

.eye {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.eye__outer, .eye__inner, .eye__lashes-up, .eye__lashes_down {
  stroke: #000;
  fill: none;
  stroke-width: 1.5px;
}

@media (width <= 740px) {
  .project__description {
    text-align: center;
  }

  .project__description p {
    width: 70vw;
    margin-top: 45px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (width <= 768px) {
  .coolCircleEyeButton .textcircle {
    animation: 7s linear infinite rotate;
  }

  .coolCircleEyeButton:hover .textcircle {
    animation: none;
  }
}

@media (width <= 320px) {
  h1 {
    font-size: 40px;
  }
}

@media (width >= 650px) {
  .section {
    padding: 5rem;
  }
}

.fargions {
  background-color: #fff;
  margin-top: 150px;
}

.fargion-header {
  margin-left: 20px;
}

h3 {
  white-space: nowrap;
}

.text-container {
  overflow-wrap: break-word;
  width: 500px;
}

.text-container-fargionsbio {
  min-width: 250px;
}

.text-container p {
  word-wrap: break-word;
  font-size: 15px;
  line-height: 2rem;
}

.item img {
  object-fit: cover;
  border-radius: 5px;
  max-width: 600px;
  margin-left: 20px;
}

@media (width <= 600px) {
  .item img {
    width: 450px;
  }
}

section.horizontal {
  overflow-x: hidden;
}

section.horizontal .pin-wrap, section.horizontal .animation-wrap {
  z-index: 1;
  height: 100vh;
  display: flex;
  position: relative;
}

section.horizontal .item {
  -webkit-user-select: none;
  user-select: none;
  background-color: #0000;
  border-right: 1px solid #0000000f;
  flex: 0 0 500px;
  align-items: center;
  padding: 150px 80px;
  line-height: 1.7;
  display: flex;
  position: relative;
}

@media (width <= 425px) {
  section.horizontal .item {
    padding: 50px;
  }
}

section.horizontal .item:before {
  opacity: .13;
  z-index: -1;
  font-size: 100px;
  font-weight: bold;
  line-height: 1;
  position: absolute;
  transform: translate(-30px, -50px);
}

section.horizontal .animation-wrap .item:nth-child(2n+2) {
  align-items: flex-start;
}

section.horizontal .animation-wrap .item:nth-child(4n+4) {
  align-items: flex-end;
}

@media (width <= 560px) {
  #fargionbio {
    overflow-wrap: break-word;
  }
}

@media (width <= 320px) {
  h1 {
    font-size: 40px;
  }
}

@media (width >= 650px) {
  .section {
    padding: 5rem;
  }
}

.contact {
  padding: 50px;
}

.contact-container {
  z-index: 1;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
  padding: 2rem;
  font-family: sans-serif;
  font-weight: bold;
  display: flex;
  position: relative;
  overflow: hidden;
}

.contact-container:before {
  content: "";
  z-index: -2;
  background-color: #feffff;
  background-image: linear-gradient(#c4d3c8, #f9f9f9), linear-gradient(#e2c98a, #fbb300), linear-gradient(#fff, #f5dddc), linear-gradient(#377af5, #377af5);
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-repeat: no-repeat;
  background-size: 50% 50%, 50% 50%;
  width: 200%;
  height: 200%;
  animation: 15s linear infinite rotate;
  position: absolute;
  top: -50%;
  left: -50%;
}

.contact-container:after {
  content: "";
  z-index: -1;
  background: #000;
  border-radius: 3px;
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  position: absolute;
  top: 6px;
  left: 6px;
}

.contact-text {
  word-wrap: break-word;
  overflow-wrap: break-word;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 5px;
  display: flex;
}

.contact-text span {
  color: #fff;
  font-size: 20px;
}

.contact-text h2 {
  color: #fff;
  font-size: 50px;
}

.contact-text button {
  z-index: 1;
  color: #fff;
  background: none;
  border: 1px solid #fff;
  border-radius: 50px;
  padding: 20px;
}

@media (width <= 1024px) {
  .contact-container {
    width: 90vw;
  }
}

@media (width <= 900px) {
  .contact-text span {
    font-size: 15px;
  }

  .contact-text h2 {
    font-size: 25px;
  }
}

@media (width <= 600px) {
  .contact {
    padding: 20px;
  }

  .contact-container {
    border-radius: 20px;
    height: auto;
  }

  .contact-text {
    padding: 10px;
  }

  .contact-text span {
    font-size: 14px;
  }

  .contact-text h2 {
    font-size: 20px;
  }

  .contact-text button {
    padding: 10px;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
}

:after, :before {
  box-sizing: border-box;
}

html, body {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  font-family: Poppins, sans-serif;
  line-height: 1.5;
}

button, input, textarea, select {
  font: inherit;
}

h1 {
  font-size: 60px;
}

@media (width <= 320px) {
  h1 {
    font-size: 40px;
  }
}

a {
  color: inherit;
  z-index: 2;
  pointer-events: auto;
  text-decoration: none;
}

ul {
  list-style: none;
}

.section {
  min-height: 100vh;
  padding: 5rem 1rem 1rem;
  overflow: hidden;
}

@media (width >= 650px) {
  .section {
    padding: 5rem;
  }
}

.mastering {
  text-align: center;
  z-index: 1;
  background: #000;
  height: 600px;
  padding-top: 1rem;
  font-size: 50px;
  position: relative;
  overflow: hidden;
}

.mastering__header {
  color: #fff;
  font-weight: 600;
}

.mastering__text {
  white-space: nowrap;
  color: #fff;
  margin-top: 100px;
  font-size: 30px;
  position: absolute;
}

.mastering__text--left {
  animation: 40s linear infinite moveleft;
  top: 5rem;
  left: 0;
}

@keyframes moveleft {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

.mastering__text--right {
  animation: 40s linear infinite moveright;
  top: 20rem;
  right: 0;
}

@keyframes moveright {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(50%, 0, 0);
  }
}

.mastering .mastering__details {
  color: #fff;
  padding-top: 5px;
  font-size: 25px;
}

@media (width <= 700px) {
  .mastering {
    height: 400px;
  }

  .mastering__text {
    font-size: 25px;
  }

  .mastering__text--left {
    top: 5rem;
  }

  .mastering__text--right {
    top: 11rem;
  }
}
/*# sourceMappingURL=index.20106b3e.css.map */
